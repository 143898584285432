let zoomEffect = {
    init: function () {
        const inner = document.querySelector(".js-zoom");
        const section = document.querySelector(".js-zoom-next");

        window.onscroll = function () {
            let value = window.pageYOffset / (section.offsetTop * 3) + 1;
            inner.style.transform = `scale(${value})`;
        };
    },
} 